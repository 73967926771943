import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P45-E1",
    exerciseKey: "/img/FriendsPlus/Page45/Key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      fontSize: 22,
      width: 230,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "heavy traffic",
          "<s>leisure facilities</s>",
          "music venue",
          "pedestrian walkways",
          "railway line",
          "skatepark",
          "sports stadium",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: ` Complete the passage with so or such.

`,
        left: 50,
        star: 1,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;position:relative'>
            <img src='/img/FriendsPlus/Page45/11.jpg' style='width:600px' />
            <div style='position:absolute;top:227px;left:203px'> <input id=0 /> </div>
            <div style='position:absolute;top:315px;left:195px'> <input id=1 /> </div>
            <div style='position:absolute;top:401px;left:-11px'> <input id=2 /> </div>
            <div style='position:absolute;top:458px;left:-11px'> <input id=3 /> </div>
            
        </div>
      
        `,
        answer: ["such", "so", "so", "such"],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P45-E2",
    exerciseKey: "/img/FriendsPlus/Page45/Key/e2-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` You're visiting London. Where can you
        hear people say these things? Choose the correct
        words and match 1–6 with situations a–f.
`,
        left: -50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 50,
        },
        answers: ["d", "c", "b", "f", "a"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",

          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "such / so",
          "such / so",
          "such / so",
          "such / so",
          "such / so",
        ],
        answers: ["0-0", "1-0", "2-4", "3-4", "4-4"],
        initialValue: [],
      },
      Layout: `
        
           <div style='margin-top:20px;position:relative'>
            'There are
            <span > <b> <span style='border:1px solid;border-radius:50%;'>so many</span> / so much </b> </span>
            people on here that you should book this 30-minute-ride <br> ticket in advance.’
            <span style='border-bottom:2px dotted;font-weight:bold'>e</span>
          
          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span >  ‘We’ve got  </span>
            <input id=0 type=Circle />
            <span> cool 'London' T-shirts in plenty of colours that we don't need <br> to buy more.'  </span>
            <input id=0 />
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span > ‘We’re closing very soon. So you have </span>
            <input id=1 type=Circle />
            <span> little time left that you can't see <br> all the dinosaurs here.</span>
            <input id=1 />
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span > ‘When there’s an important match, the stadium is </span>
            <input id=2 type=Circle /> 
            <span> crowded that you <br> hardly find an empty seat.'</span>
            <input id=2 />
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span > ‘Read this short leaflet about the famous paintings. The information is </span>
            <input id=3 type=Circle /> <br>
             <span> useful that you should take notes for future reference.’</span>
            <input id=3 />
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 5 </span>
            <span > ‘The giraffes here are </span>
            <input id=4 type=Circle />
            <span> friendly that my kids approach and feed them <br> with some grass.'</span>
            <input id=4 />
          </div>
        <br>
          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > a </span>
            <span > At London Zoo in Regent’s Park  </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > b </span>
            <span > On a tour at Chelsea football stadium  </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > c </span>
            <span > At the Natural History Museum  </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > d </span>
            <span > At Camden Market  </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > e </span>
            <span > At the London Eye  </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > f </span>
            <span > At the Tate Britain art gallery  </span>
          </div>

         

       </div>
      `,
    },
  },
  3: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P45-E3",
    exerciseKey: "/img/FriendsPlus/Page45/Key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      width: 800,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "heavy traffic",
          "<s>leisure facilities</s>",
          "music venue",
          "pedestrian walkways",
          "railway line",
          "skatepark",
          "sports stadium",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Rewrite the following sentences beginning with the words given.

`,
        left: 50,
        star: 3,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span> The transformation was so extraordinary that it turned the park into a vibrant gathering place. </span> <br>

                    <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> It was such an extraordinary transformation that it turned the park into a vibrant gathering place.  </span>

                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span>
                    <span >
                      There was such positive impact that families began flocking to the park. The impact was so
                    </span>
                    <textarea rows=2 id=0 ></textarea>
                </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 2 </span>
                <span>
                  The atmosphere became so lively that it attracted local artists to host outdoor events. It was such
                </span>
                <textarea rows=2 id=1 ></textarea>
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 3 </span>
                <span>
                  The transformation was so profound that it sparked a sense of pride among the residents. It was such
                </span>
                <textarea rows=2 id=2 ></textarea> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 4 </span>
                <span>
                  The park became such a vivid symbol of revitalisation that neighbouring communities
                  began to consider their own improvement projects. The park became a symbol
                  of revitalisation that was so
                </span>
                <textarea rows=2 id=3 ></textarea> 
              </div>
            
            </div>
           
        </div>
      
        `,
        answer: [
          "The impact was so positive that families began flocking to the park",
          "It was such lively atmosphere that it attracted local artists to host outdoor events.",
          "It was such a profound transformation that it sparked a sense of pride among the residents.",
          "The park became a symbol of revitalisation that was so vivid that neighbouring communities began to consider their own improvement projects.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P45-E4",
    exerciseKey: "/img/FriendsPlus/Page45/Key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      width: 950,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "awesome places to visit",
          "big hotels",
          "cool shops and markets",
          "fun entertainment",
          "historic buildings",
          "nice parks to walk in",
        ],
      },
      {
        src: [
          "big queues",
          "cheap places to eat",
          "crowds of tourists",
          "green spaces",
          "litter",
          "space on public transport",
          "traffic",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: ` Write about a city that is popular with
        tourists in Việt Nam. Use adverbial clauses of result
        and the words in the boxes or your own ideas.

`,
        left: 50,
        star: 3,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    KeyMau: true,
    isHello: true,
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <hintbox id=1 ></hintbox>
        <div style='margin-top:20px;'>
          <p style='font-weight:bold'>Positive things</p>
          <span style='font-weight:bold;border-bottom:1px dotted'>
            There are such awesome places to visit in Hà Nôi like
            Uncle Hô Mausoleum <br> and the Sword Lake that you
            need to spend at least two days experiencing the city.
          </span>
          <input id=0 />
          <input id=1 />
          <input id=2 />
           
        </div>

        <div style='margin-top:20px;'>
          <p style='font-weight:bold'>Negative things</p>
          <span style='font-weight:bold;border-bottom:1px dotted'>
            During rush hours, the traffic is so heavy that you
            may feel tired travelling by taxi.
          </span>
          <input id=3 />
          <input id=4 />
          <input id=5 />
           
        </div>
      
        `,
        answer: [
          "There are lots of historic buildings",
          "There are plenty of cool shops in the centre",
          "There are lots of nice parks to walk around.",
          "There are often too many big queues to get into the museums",
          "There are only a few cheap places to eat in the centre",
          "There isn't enough space on public transport",
        ],
      },
    ],
  },
};

export default json;
