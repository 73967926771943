import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P47-E1",
    exerciseKey: "/img/FriendsPlus/Page47/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      fontSize: 22,
      width: 180,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "found",
          "has",
          "haven’t",
          "invited",
          "isn’t",
          "might",
          "’m",
          "preserved",
          "’re",
          "wasn’t",
          "were",
          "will",
          "won’t",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `  Complete the table with the words

`,
        left: 50,
        star: 1,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;position:relative'>
            <img src='/img/FriendsPlus/Page47/11.jpg' style='width:600px' />
            <div style='position:absolute;top:122px;left:73px'> <input id=0  /> </div>
            <div style='position:absolute;top:154px;left:51px'> <input id=1  /> </div>
            <div style='position:absolute;top:188px;left:51px'> <input id=2  /> </div>
            <div style='position:absolute;top:271px;left:82px'> <input id=3  /> </div>
            <div style='position:absolute;top:306px;left:217px'> <input id=4  /> </div>
            <div style='position:absolute;top:338px;left:4px'> <input id=5  /> </div>
            <div style='position:absolute;top:421px;left:130px'> <input id=6  /> </div>
            <div style='position:absolute;top:455px;left:46px'> <input id=7  /> </div>
            <div style='position:absolute;top:489px;left:122px'> <input id=8  /> </div>
            <div style='position:absolute;top:572px;left:122px'> <input id=9  /> </div>
            <div style='position:absolute;top:606px;left:11px'> <input id=10  /> </div>
            <div style='position:absolute;top:689px;left:24px'> <input id=11  /> </div>
            
        </div>
      
        `,
        answer: [
          "isn't",
          "'re",
          "invited",
          "wasn't",
          "found",
          "Were",
          "haven't",
          "has",
          "preserved",
          "won't",
          "Will",
          "might",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P47-E2",
    exerciseKey: "/img/FriendsPlus/Page47/key/e2-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Complete the quiz sentences below with
          the correct passive form of the verbs in brackets. Then
          choose the correct answers.
`,
        left: -50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "none",
          // borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
          fontSize: 22,
        },
        answers: [
          "were unearthed",
          "was built",
          "was created",
          "were discovered",
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",

          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "Greece / Egypt / Italy",
          "Rome / Cairo / Athens",
          "Inca / Aztec / Mayan",
          "France / Italy / Turkey",
        ],
        answers: ["0-4", "1-8", "2-0", "3-4"],
        initialValue: [],
      },
      Layout: `
        
        <div style='margin-top:20px;position:relative'>
            <img src='/img/FriendsPlus/Page47/12.jpg' style='width:600px;margin-left:200px' />
            <div style='position:absolute;top:144px;left:399px'> <input id=0 type=Circle /> </div>
            <div style='position:absolute;top:207px;left:384px'> <input id=1 type=Circle /> </div>
            <div style='position:absolute;top:300px;left:269px'> <input id=2 type=Circle /> </div>
            <div style='position:absolute;top:395px;left:300px'> <input id=3 type=Circle /> </div>

            <div style='position:absolute;top:111px;left:530px'> <input id=0 /> </div>
            <div style='position:absolute;top:173px;left:424px'> <input id=1 /> </div>
            <div style='position:absolute;top:267px;left:357px'> <input id=2 /> </div>
            <div style='position:absolute;top:360px;left:402px'> <input id=3 /> </div>
            
            
            
        </div>
      `,
    },
  },
  3: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P47-E3",
    exerciseKey: "/img/FriendsPlus/Page47/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      width: 350,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "found",
          "has",
          "haven’t",
          "invited",
          "isn’t",
          "might",
          "’m",
          "preserved",
          "’re",
          "wasn’t",
          "were",
          "will",
          "won’t",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `  Complete the second sentence so that it
        has the same meaning as the first sentence. Use the
        passive. Only use by where necessary.

`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span> People don’t find Roman jewellery very often. Roman jewellery</span> 
                    <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> isn’t found very often.  </span>
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span>
                    They will build the boat next year. The boat
                    <input  id=0 ></input>
                   

                </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 2 </span>
                A museum might buy the vase.
                The vase
                <input  id=1 ></input>
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 3 </span>
                They haven’t renovated the old church.
                The old church
                <input  id=2 ></input>
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 4 </span>
                The expert won’t examine the gold cup.
                The gold cup
                <input  id=3 ></input>
              </div>
            
            </div>
           
        </div>
      
        `,
        answer: [
          "will be built next year",
          "might be bought by a museum",
          "hasn't been renovated",
          "won't be examined by the expert.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P47-E4",
    exerciseKey: "/img/FriendsPlus/Page47/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      width: 800,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["how many", "what", "when", "<s>where</s>", "who", "why"],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `  Write passive questions for the underlined
        words. Use a question word from the box.

`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'>
                      Where were some ancient paintings discovered?
                    </span> <br>
                    <span>
                      Some ancient paintings were discovered
                      <span style='text-decoration:underline;'>in the southern Turkish city of Mersin.</span>
                      
                    </span> 
                    
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span>
                    <input  id=0 ></input> <br>
                    <span style='margin-left:25px;'>The cave paintings were discovered </span>
                    <span style='text-decoration:underline;'>in 1916</span>.
                </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 2 </span>
                <input  id=1 ></input> <br>
                <span style='text-decoration:underline;margin-left:25px;'>Ten</span> cave paintings can be seen
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 3 </span>
                <input  id=2 ></input> <br>
                <span style='margin-left:25px;'>The paintings were discovered by </span>
                <span style='text-decoration:underline;'>a group of
                  Turkish archaeologists.</span>
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 4 </span>
                <input  id=3 ></input> <br>
                <span style='margin-left:25px;'>The paintings have been admired </span>
                <span style='text-decoration:underline;'> because they show early pictures of humans dancing.</span>
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 5 </span>
                <input  id=4 ></input> <br>
                <span style='text-decoration:underline;;margin-left:25px;'> More books about the history of cave paintings</span>
                <span > will be written in the future. </span>
              </div>
            
            </div>
           
        </div>
      
        `,
        answer: [
          "When were the cave paintings discovered?",
          "How many cave paintings can be seen?",
          "Who were the paintings discovered by?",
          "Why have the paintings been admired?",
          "What will be written in the future?",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P47-E5",
    exerciseKey: "/img/FriendsPlus/Page47/key/e5-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      width: 800,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["how many", "what", "when", "<s>where</s>", "who", "why"],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: ` Look at the photo of China’s Terracotta
        Army soldiers. Write four questions about them
        using the past, present and future passive.

`,
        left: 50,
        star: 3,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    KeyMau: true,
    isHello: true,
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'>
                      How were the soldiers found?
                    </span> 
                </div> <br>
                <input id=0 />
                <input id=1 />
                <input id=2 />
                <input id=3 />
                <input id=4 />

                <img src='/img/FriendsPlus/Page47/13.jpg' style='width:600px;margin-left:150px;margin-top:40px' />
            </div>
           
        </div>
      
        `,
        answer: [
          "When were the cave paintings discovered?",
          "How many cave paintings can be seen?",
          "Who were the paintings discovered by?",
          "Why have the paintings been admired?",
          "What will be written in the future?",
        ],
      },
    ],
  },
};

export default json;
