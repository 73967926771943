import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P48-E1",
    exerciseKey: "/img/FriendsPlus/Page48/key/e1-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: ` Look quickly at the online texts and choose
        the correct answers.
`,
        left: -50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "none",
          // borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
          fontSize: 22,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",

          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "an&nbsponline&nbspencyclopaedia / a&nbsplocal&nbspgovernment&nbspwebsite / a&nbspskateboarding&nbspfan&nbspwebsite.",
          "a&nbspsocial&nbspmedia&nbspupdate / a&nbspskateboarding&nbspequipment&nbspwebsite / a&nbspreview&nbspon&nbspa&nbsppersonal&nbspblog.",
        ],
        answers: ["0-4", "1-8", "2-0", "3-4"],
        initialValue: [],
      },
      Layout: `
        
       <div style='margin-top:20px;'>
                
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span>
                    Text A comes from
                    <input  id=0 type=Circle ></input>
                </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 2 </span>
                Text B comes from
                <input  id=1 type=Circle ></input>
              </div>

              <img src='/img/FriendsPlus/Page48/9.jpg' style='width:1000px;margin-top:40px;' />
            
            </div>
           
        </div>
      `,
    },
  },
  2: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P48-E2",
    exerciseKey: "/img/FriendsPlus/Page48/key/e2-key.png",
    audio: "/Audios/1.16.mp3",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      width: 50,
      paddingTop: 10,
      maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "found",
          "has",
          "haven’t",
          "invited",
          "isn’t",
          "might",
          "’m",
          "preserved",
          "’re",
          "wasn’t",
          "were",
          "will",
          "won’t",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=16 colorImg=#f0ab9c></headphone>  Read and listen to the online texts. Write text A or B.

`,
        left: 50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
         <div style='margin-top:20px;'>
          <div style='display:flex'>
            <div>
                <div style='margin-top:20px;'>
                    <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> A </span>
                    <span> information about opening hours </span>
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span>
                    <input id=0 /> 
                    <span > rules  </span>
                </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 2 </span>
                <input id=1 /> 
                <span> personal opinions  </span> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 3 </span>
                <input id=2 /> 
                <span> a serious warning </span> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 4 </span>
                <input id=3/> 
                <span> informal language   </span> 
              </div>
            
            </div>

            <div style='margin-left:60px'>
            <div style='margin-top:20px;color:white'>
                <span>xxxxx  </span> 
              </div>
              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 5 </span>
                <input id=4/> 
                <span> formal language  </span> 
              </div>

             <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 6 </span>
                <input id=5/> 
                <span> only facts </span>
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 7 </span>
                <input id=6/> 
                <span> a piece of advice </span>
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 8 </span>
                <input id=7/> 
                <span> BMX vocabulary </span>
              </div>
            
            </div>
            </div>
            <img src='/img/FriendsPlus/Page48/9.jpg' style='width:1000px;margin-top:40px;' />
         

        </div>
      
      
        `,
        answer: ["A", "B", "A", "B", "A", "A", "B", "B"],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P48-E3",
    exerciseKey: "/img/FriendsPlus/Page48/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      width: 700,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "found",
          "has",
          "haven’t",
          "invited",
          "isn’t",
          "might",
          "’m",
          "preserved",
          "’re",
          "wasn’t",
          "were",
          "will",
          "won’t",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Answer the questions. Write complete sentences.

`,
        left: 50,
        star: 3,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
         <div style='margin-top:20px;'>
          <div>
                <div style='margin-top:20px;'>
                  <span> What was the site before it became an urban sports park?  </span>
                  <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'>  It was an industrial site </span>
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
                    <span > What are two ways that people can reach the sports park? </span> <br>
                    <input id=0 /> 
                </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 2 </span>
                <span> What type of safety equipment does everybody have to use? </span> <br>
                <input id=1 /> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 3 </span>
                <span> When is it not safe to use the facilities? </span>  <br>
                <input id=2 /> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 4 </span>
                <span> What is Owen’s opinion of the location? Which phrase shows this? </span> <br>
                <input id=3/> 
              </div>
            
            </div>

             <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 5 </span>
                <span> What sport does Owen do? How do you know? </span> <br>
                <input id=4/> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 6 </span>
                <span> What negative point does Owen make about the sports park? </span> <br>
                <input id=5/> 
              </div>
            
            </div>
         

        </div>
      
      
        `,
        answer: [
          "They can walk for five minutes from the city centre or park next door",
          "Everybody has to wear a helmet",
          "It isn't safe to use them in wet weather.",
          "He likes it a lot. He gives it '10 out of 10 for the coolest location'",
          "He does BMXing. He calls himself a 'BMXer'",
          "He says that it gets very noisy at rush hour.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P48-E4",
    exerciseKey: "/img/FriendsPlus/Page48/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      width: 250,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "found",
          "has",
          "haven’t",
          "invited",
          "isn’t",
          "might",
          "’m",
          "preserved",
          "’re",
          "wasn’t",
          "were",
          "will",
          "won’t",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: ` VOCABULARY PLUS Complete the
        sentences with the comparative or superlative form
        of the adjectives in brackets.

`,
        left: 50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
         <div style='margin-top:20px;'>
          <div>
                <div style='margin-top:20px;'>
                  <span> The  </span>
                  <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> nearest </span>
                   (near) skatepark is in the city centre.
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
                    <span > I’ve been cycling today and I’m feeling </span> 
                    <input id=0 />
                     (tired) than yesterday
                </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 2 </span>
                <span> Is that </span> 
                <input id=1 />
                (dangerous) jump that you can do on your skateboard?
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 3 </span>
                <span> That’s </span>  
                <input id=2 />
                (cool) BMX bike that I’ve seen for a long time.
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 4 </span>
                <span> The surface of this skatepark is a lot </span> 
                <input id=3/>
                 (smooth) than usual.
              </div>
            
            </div>

             <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 5 </span>
                <span> Where are </span> 
                <input id=4/>
                (modern) leisure facilities in your area?
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 6 </span>
                <span> Those skateboarders are </span> 
                <input id=5/>
                (bad) than us.
              </div>
            
            </div>
         

        </div>
      
      
        `,
        answer: [
          "more tired",
          "the most dangerous",
          "the coolest",
          "smoother",
          "the most modern",
          "worse",
        ],
      },
    ],
  },
};

export default json;
