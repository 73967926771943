// import p1 from "./1";
// import p2 from "./2";
// import p3 from "./3";
import p4 from "./4";
import p5 from "./5";
import p6 from "./6";
import p7 from "./7";
import p8 from "./8";
import p9 from "./9";
import p10 from "./10";
import p11 from "./11";
import p12 from "./12";
import p13 from "./13";
import p14 from "./14";
import p15 from "./15";
import p16 from "./16";
import p17 from "./17";
import p18 from "./18";
import p19 from "./19";
import p20 from "./20";
import p21 from "./21";
import p22 from "./22";
import p23 from "./23";
import p24 from "./24";
import p25 from "./25";
import p26 from "./26";
import p27 from "./27";
import p28 from "./28";
import p29 from "./29";
import p30 from "./30";
import p31 from "./31";
import p32 from "./32";
import p33 from "./33";
import p34 from "./34";
import p35 from "./35";
import p36 from "./36";
import p37 from "./37";
import p38 from "./38";
import p39 from "./39";
import p40 from "./40";
import p41 from "./41";
import p42 from "./42";
import p43 from "./43";
import p44 from "./44";
import p45 from "./45";
import p46 from "./46";
import p47 from "./47";
import p48 from "./48";
import p49 from "./49";
import p50 from "./50";
import p51 from "./51";
import p52 from "./52";
import p53 from "./53";
import p54 from "./54";
import p55 from "./55";
import p56 from "./56";
import p57 from "./57";
import p58 from "./58";
import p59 from "./59";
import p60 from "./60";
import p61 from "./61";
import p62 from "./62";
import p63 from "./63";
import p64 from "./64";
import p65 from "./65";
import p66 from "./66";
import p67 from "./67";
import p68 from "./68";
import p69 from "./69";
import p70 from "./70";
// import p71 from "./71";
// import p72 from "./72";
// import p73 from "./73";
// import p74 from "./74";
// import p75 from "./75";
// import p76 from "./76";
// import p77 from "./77";
// import p78 from "./78";
// import p79 from "./79";
// import p80 from "./80";
// import p81 from "./81";
// import p82 from "./82";
// // import p83 from "./83";
// import p84 from "./84";
// import p85 from "./85";
// import p86 from "./86";
// import p87 from "./87";
// import p88 from "./88";
// import p89 from "./89";
// import p90 from "./90";
// import p91 from "./91";
// import p92 from "./92";
// import p93 from "./93";
// import p94 from "./94";
// import p95 from "./95";
// import p96 from "./96";
// import p97 from "./97";
// import p98 from "./98";
// import p99 from "./99";
// import p100 from "./100";
// import p101 from "./101";
// import p102 from "./102";
// import p103 from "./103";

const pageQuestions = {
  // 1: p1,
  // 2: p2,
  // 3: p3,
  4: p44,
  5: p45,
  6: p46,
  7: p47,
  8: p48,
  9: p49,
  // 10: p10,
  // 11: p11,
  // 12: p12,
  // 13: p13,
  // 14: p14,
  // 15: p15,
  // 16: p16,
  // 17: p17,
  // 18: p18,
  // 19: p19,
  // 20: p20,
  // 21: p21,
  // 22: p22,
  // 23: p23,
  // 24: p24,
  // 25: p25,
  // 26: p26,
  // 27: p27,
  // 28: p28,
  // 29: p29,
  // 30: p30,
  // 31: p31,
  // 32: p32,
  // 33: p33,
  // 34: p34,
  // 35: p35,
  // 36: p36,
  // 37: p37,
  // 38: p38,
  // 39: p39,
  // 40: p40,
  // 41: p41,
  // 42: p42,
  // 43: p43,
  // 44: p44,
  // 45: p45,
  // 46: p46,
  // 47: p47,
  // 48: p48,
  // 49: p49,
  // 50: p50,
  // 51: p51,
  // 52: p52,
  // 53: p53,
  // 54: p54,
  // 55: p55,
  // 56: p56,
  // 57: p57,
  // 58: p58,
  // 59: p59,
  // 60: p60,
  // 61: p61,
  // 62: p62,
  // 63: p63,
  // 64: p64,
  // 65: p65,
  // 66: p66,
  // 67: p67,
  // 68: p68,
  // 69: p69,
  // 70: p70,
  // 71: p71,
  // 72: p72,
  // 73: p73,
  // 74: p74,
  // 75: p75,
  // 76: p76,
  // 77: p77,
  // 78: p78,
  // 79: p79,
  // 80: p80,
  // 81: p81,
  // 82: p82,
  // 83: p83,
  // 84: p84,
  // 85: p85,
  // 86: p86,
  // 87: p87,
  // 88: p88,
  // 89: p89,
  // 90: p90,
  // 91: p91,
  // 92: p92,
  // 93: p93,
  // 94: p94,
  // 95: p95,
  // 96: p96,
  // 97: p97,
  // 98: p98,
  // 99: p99,
  // 100: p100,
  // 101: p101,
  // 102: p102,
  // 103: p103,
};

export default pageQuestions;
